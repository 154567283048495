@import 'variables';


.main-table {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    table {
        margin-top: 50px;
        height: 80%;
        width: 80%;
        background-color: $primary-color;
        color: $secondary-color;
        border-radius: 20px;
        overflow: hidden;

        tr {
            width: 100%;
            height: 60px;
            cursor: pointer;
        }

        td {
            text-align: center;
            width: 100px;
        }

        .team-name {
            width: 200px;
        }

        tr:hover {
            background-color: $third-color;
        }
    }

}


@media screen and (max-width: $small-screen) {

    .main-table {
        
        table {
            margin-top: 20px;
            height: 90%;
            width: 90%;
            font-size: 14px;
        }
    
    }

    .hidden-phone{
        display: none;
    }
}