@import "variables";

.gotw {
  display: flex;
  justify-content: center;
  gap: 100px;
  width: 100%;
}

.goal-video {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  iframe {
    border: 2px solid $primary-color;
    border-radius: 5px;
    overflow: hidden;
    width: 913px;
    height: 513.562px;
  }

  p {
    color: $primary-color;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
  }
}

.video-selector {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.video-option {
  border: 2px solid $primary-color;
  border-radius: 5px;
  height: 140px;
  width: 250px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }

  &:hover img {
    transform: scale(1.2);
  }
}

@media screen and (max-width: $small-screen) {
  .gotw {
    flex-direction: column;
  }
  .goal-video {
    width: 100%;

    iframe {
      height: 100%;
      width: 80%;
    }

    p {
      font-size: 15px;
    }
  }

  .video-selector{
    width: 100%;
    flex-direction: row;
  }

  .video-option {
    width: 100px;
    height: 54px;
  }
}
