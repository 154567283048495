@import "variables";

.potm {
  position: relative;
  width: 300px;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1));
  }

  &:hover {
    width: 350px;

    &::before {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)); // Change background on hover for ::before
    }
  }
  .overlay {
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-align: center;
    justify-content:flex-end;
    font-weight: 700;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12%;
    color: white;
    visibility: hidden;
    transition: visibility 0s, opacity 0.8s ease; 

     p {
      margin: 0;
      font-family: 'Archivo Black', sans-serif;
      width: 100%;
     }

      p:first-child{
        font-size: 30px;
      }
  }

  &:hover {
    width: 350px; // Increase the width on hover
    background:  linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    .overlay {
      background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
      visibility: visible; // Show the overlay on hover
    }
  }
}


@media screen and (max-width: $small-screen) {
  .potm {
    width: 50%;
    height: 50%;

    &:hover {
      width: 50%;
    }

    .overlay {
      height: 20%;
      p:first-child{
        font-size: 10px;
      }
      p:nth-child(2){
        font-size: 8px;
      }
  }

  }



}