@import 'variables';


.table-page {
    padding-top: 60px;
    padding-right: 60px;
    height: 1560px;
    width: 100%;
    background-color: $secondary-color;
  }

  @media screen and (max-width: $small-screen) {

    .table-page {
      height: 190%;
      padding-top: 35px;
      padding-right: 35px;
    }
}