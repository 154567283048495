@import "variables";

.app-container {
  display: flex;
  height: 100%;
  width: 100%;
}

h1 {
  margin: 0;
  background-color: $secondary-color;
  color: $primary-color;
  font-family: 'Archivo Black', sans-serif;
}

.main {
  padding-top: 60px;
  padding-right: 60px;
  height: 3060px;
  width: 100%;
  background-color: $secondary-color;
  float: left;
}

.hot-news-container {
  display: flex;
  justify-content: center;
  height: 660px;
  width: 100%;
}

.matches-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px;
  height: 1000px;
  width: 100%;
  background-color: $secondary-color;


}

.potm-container {
  width: 100%;
  height: 600px;
  background-color: $primary-color;
  display: flex;
  align-items: center;

  .potms {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 80%;
  }

  .potm-banner {
    height: 100%;
    width: 30%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img{
      height: 250px;
    }
    
    .banner-text {
      p {
        margin: 0;
      }

      p:first-child {
        font-size: 40px;
        font-family: 'Archivo Black', sans-serif;
        color: white;
      }
    }
  }
}


.gotw-container {
  padding-top: 20px;
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


@media screen and (max-width: $small-screen) {

  h1 {
    font-size: 20px;
  }

  .main {
    padding-top: 35px;
    padding-right: 35px;
    height: 1760px;
  }

  .hot-news-container {
    height: 245px;
    
  }

  .matches-container {
    justify-content: flex-start;
    height: 580px;
    text-align: center;
    padding: 0;
    padding-top: 20px;
  }

  .potm-container {
    flex-direction: column;
    height: 600px;

    .potms {
      display: flex;
      flex-wrap: wrap;
      height: 50%;
      width: 100%;
    }
  
    .potm-banner {
      height: 300px;
      width: 100%;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;


      img {
        height: 100px;
      }
    }
  }


  .gotw-container {
    justify-content: flex-start;
    height: 400px;
  }
}