@import "variables";

* {
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

body {
  margin: 0;
}

.App {
  width: 100%;
  height: 100vh;
  color: $primary-color;
  display: flex;
  flex-direction: column;
}
