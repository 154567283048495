@import "variables";

.sidebar {
  width: 60px;
  height: 1020px;
  position: fixed;
  border-left: 1px solid $secondary-color;
  background-color: $primary-color;
  z-index: 99;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 15px;
}

.line {
  height: 1px;
  background-color: $secondary-color;
  width: 45px;
  border-radius: 5px;
}

.clubs {
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .club-logo {
    max-width: 35px;
    object-fit: cover;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: $small-screen) {
  .sidebar {
 
    width: 35px;
    height: 100%;
  }

  .line {
    width: 15px;
  }

  .clubs {
    .club-logo {
      max-width: 20px;
    }
  }
}
